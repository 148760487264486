#HeaderLanguageSwitcher {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

#HeaderLanguageSwitcher .divider{
    width: 2px;
    background-color: white;
    overflow: hidden;
    border-radius: 50%;
}