@media only screen and (max-width: 860px) {
    h1 {
        font-size: calc(0.6vw + 1.2rem) !important;
        font-weight: 800 !important;
    }

    h2 {
        font-size: calc(0.6vw + 1.1rem) !important;
        font-weight: 600 !important;
    }

    h3 {
        font-size: calc(0.6vw + 1rem) !important;
        font-weight: 600 !important;
    }

    h4 {
        font-size: calc(0.6vw + 0.9rem) !important;
        font-weight: 600 !important;
    }

    p {
        font-size: calc(0.6vw + 0.7rem) !important;
        font-weight: 200 !important;
        line-height: 1.15 !important;
    }

    #root .info-box {
        width: 300px;
    }
}