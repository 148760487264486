#Dashboard {
    display: flex;
    flex-direction: row;
    height: 100vh;
}

#Dashboard-sidebar {
    width: 250px;
    background: #f8f9fa;
    padding: 20px;
}

#Dashboard-main {
    flex-grow: 1;
    padding: 20px;
}

#Dashboard .list-group-item {
    cursor: pointer;
}

#Dashboard .list-group-item.active {
    background-color: #007bff;
    color: white;
}
