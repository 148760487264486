#HeaderNavBurgermenu .burger {
    display: flex;
    flex-direction: column;
    gap: 5px;
    cursor: pointer;
}

#HeaderNavBurgermenu .burger span {
    width: 30px;
    height: 2px;
    background: white;
}

#HeaderNavBurgermenu .burger.active span:nth-child(1) {
    width: 32px;
    transform: translate(0px, 7px) rotate(45deg);
}

#HeaderNavBurgermenu .burger.active span:nth-child(2) {
    opacity: 0;
}

#HeaderNavBurgermenu .burger.active span:nth-child(3) {
    width: 32px;
    transform: translate(0px, -7px) rotate(-45deg);
}