#Alert *{
    z-index: 99;
}

#Alert img{
    width: 20px;
    height: 20px;
    object-fit: fill;
}

#Alert .ToastWrapper{
    right: 0;
}