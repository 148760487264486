#Alert .fadeOut {
    animation: alertsFadeOutAnim 0.1s forwards;
}

@keyframes alertsFadeOutAnim {
    0%{
        opacity: 1;
    }
    99%{
        opacity: 0;
    }
    100%{
        display: none;
    }
}
#Alert .toast {
    background-color: #1d1d1d;
    max-width: 220px;
    min-height: 80px;
    height: max-content;
}

#Alert .toast-body{
    text-shadow: #000000 0 0 5px, #000000 0 0 10px, #000000 0 0 10px;
    height: max-content;
}

#Alert .toast{
    background-color: #1d1d1d;
}

#Alert .toast-header {
    background-color: #0d0d0d;
}
