@media only screen and (min-width: 600px) {
    #Sidebar{
        position: fixed;
        display: flex;
        width: 64px;
        height: 100vh;
        transition: 0.2s;
        top: 0;

        z-index: 100;
        background-color: #0a0a0a;
    }

    #SidebarBackground {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: 99;
        background: rgba(0, 0, 0, 0.5);
    }

    #Sidebar .nav-item .bi {
        font-size: 1.1rem;
        padding: 0.2rem;
    }

    #Sidebar .nav-item img {
        width: 1.12rem;
        height: 1.12rem;
        padding: 0.1rem;
    }

    #Sidebar .nav-item p {
        font-size: 1rem !important;
        margin: 0;
    }

    #Sidebar .drop{
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
    }

    #Sidebar.active{
        width: 320px;
    }

    #Sidebar .dropdownUser .dropdown-item{
        display: flex;
        flex-direction: row;
        gap: 20px;
    }

    #Sidebar .dropdownUser .dropdown-item .bi{
        font-size: 12px;
    }

    #Sidebar:not(.active) .nav-link p,
    #Sidebar:not(.active) span{
        display: none;
    }

    #Sidebar .dropdown-toggle {
        gap: 1rem;
    }

    #Sidebar .nav-link{
        display: flex;
        flex-direction: row;
        align-items: center;
        text-align: left;
        background-color: #232323;

        gap: 0.2rem;
        padding: 4px;
    }

    #Sidebar:not(.active) .nav-link {
        justify-content: center;
    }

    #Sidebar .nav-link:hover{
        transition: 0.2s;
        background-color: #696969;
    }

    #Sidebar .nav-pills {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    #Sidebar .logo{
        width: 24px;
        height: 24px;
        padding: 2px;
        margin: 4px;
    }

    #Sidebar .content{
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;

        justify-content: center;
        align-items: center;
        text-align: center;

        width: calc(100vw - 64px);
        margin-left: 64px;
        min-height: 100vh;

        background-color: transparent;
    }

    #Sidebar .sidebarHandle{
        position: fixed;
        left: 10px;
        bottom: 80px;
        z-index: 101;
    }

    #Sidebar .sidebarHandle .bi{
        font-size: 1rem;
        color: white;
    }


    #Sidebar .sidebarHandle.active{
        left: 265px;
    }

    #Sidebar .content.dark{
        transition: 0.2s;
        filter: opacity(0.5);
    }

    #Sidebar.sidebarHandle button{
        color: white;
        background-color: transparent;
        border: transparent;
        border-radius: 15px;

        transition: all 0.2s;

        width: fit-content;
        padding: 6px 8px 6px 8px;
        cursor: pointer;
    }
    #Sidebar .sidebarHandle button:hover{
        background-color: rgb(100, 100, 100);
    }
}

@media only screen and (max-width: 600px) {
    #Sidebar{
        display: none !important;
        width: 100vw;
        height: 0;
        transition: 0.2s;
        top: 0;

        z-index: 100;
        background-color: #0a0a0a;
    }

    #Sidebar.active{
        display: flex !important;
        height: max-content;
    }

    #SidebarBackground {
    }

    #Sidebar .nav-item .bi {
        font-size: 1.1rem;
        padding: 0.2rem;
    }

    #Sidebar .nav-item img {
        width: 1.1rem;
        height: 1.1rem;
        padding: 0.1rem;
    }

    #Sidebar .nav-item p {
        font-size: 1rem !important;
        margin: 0;
    }

    #Sidebar .drop{
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
    }

    #Sidebar .dropdownUser .dropdown-item{
        display: flex;
        flex-direction: row;
        gap: 20px;
    }

    #Sidebar .dropdownUser .dropdown-item .bi{
        font-size: 12px;
    }

    #Sidebar:not(.active) .nav-link p,
    #Sidebar:not(.active) span{
        display: none;
    }

    #Sidebar .dropdown-toggle {
        gap: 1rem;
    }

    #Sidebar .nav-link{
        display: flex;
        flex-direction: row;
        align-items: center;
        text-align: left;
        background-color: #232323;

        padding: 4px;
    }

    #Sidebar:not(.active) .nav-link {
        justify-content: center;
    }

    #Sidebar .nav-link:hover{
        transition: 0.2s;
        background-color: #696969;
    }

    #Sidebar .nav-pills {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    #Sidebar .logo{
        width: 24px;
        height: 24px;
        padding: 2px;
        margin: 4px;
    }

    #Sidebar .content{
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;

        justify-content: center;
        align-items: center;
        text-align: center;

        width: calc(100vw - 64px);
        margin-left: 64px;
        min-height: 100vh;

        background-color: transparent;
    }

    #Sidebar .sidebarHandle{
        display: none;
    }

}