#CountUpAnimation {
    display: flex;
    flex-direction: row;
    width: 100vw;
    justify-content: space-around;
    padding: 2rem 10rem 2rem 10rem;
    text-align: center;
}

#CountUpAnimation div {
    display: flex;
    flex-direction: column;
    width: 10rem;
    height: 10rem;
    align-items: center;
    justify-content: center;
    padding: 25px;
}

#CountUpAnimation h2,
#CountUpAnimation h4{
    filter: drop-shadow(0 0 2px black);
    z-index: 1;
}

@media only screen and (max-width: 600px) {
    #CountUpAnimation {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0;
    }
    #CountUpAnimation div {
        width: 10rem;
        height: 6rem;
    }
}