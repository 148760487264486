.loginCenter{
    display: flex;
    margin: auto;
    flex-direction: column;
    gap: 10px;
    max-width: 400px;
    width: 80vw;
    background-color: rgba(0, 0, 0, 0.4);
    border: rgba(255, 255, 255, 0.1) 4px solid;
    border-radius: 20px;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.loginCenter .smallText {
    width: 100%;
    text-align: left;
    padding: 2px;
}

.loginCenter{
    text-align: center;
}

.loginCenter input{
    width: 50%;
    margin-top: 5px;
    margin-bottom: 5px;
}

.loginCenter button{
    margin-left: 50%;
    width: 40%;
    margin-top: 5px;
    margin-bottom: 5px;
}
