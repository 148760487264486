#SITENOTFOUNDTEXT{
    font-family: Poppins;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    font-weight: 900;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 400px;
    height: 120px;
    font-size: 36px;
    text-align: center;
    transform: translate(-200px, -60px);
    filter: drop-shadow(0 0 4px black);
}

#SITENOTFOUNDTEXT a{
    font-family: Poppins;
    font-weight: 900;
    font-size: 36px;
    filter: drop-shadow(0 0 4px black);
}

#SITENOTFOUNDTEXT p{
    font-family: Poppins;
    font-weight: 900;
    font-size: 36px;
    filter: drop-shadow(0 0 4px black);
}