#StreamerInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    padding: 20px;
}

#StreamerInfo h1 {
    display: flex;
    text-align: center;
    align-items: center;
    gap: 4px;
    height: 5rem;
    z-index: 1;
}

@media only screen and (max-width: 800px) {
    #carouselExampleFade{
        display: none;
    }
}

#carouselExampleFade .carousel-item,
#carouselExampleFade .carousel-inner {
    height: 320px;
    width: 600px;
}

#carouselExampleFade .carousel-item iframe{
    position: absolute;
    z-index: 2 !important;
}

#carouselExampleFade button {
    border: none;
    background-color: transparent;
    filter: drop-shadow(0 0 4px black) drop-shadow(0 0 4px black);
}

#carouselExampleFade button span {
    color: #fff;
}

#carouselExampleFade button:hover {
    background-color: transparent;
    filter: drop-shadow(0 0 4px black) drop-shadow(0 0 4px black) drop-shadow(0 0 4px black) drop-shadow(0 0 4px black) drop-shadow(0 0 4px black) drop-shadow(0 0 4px black);
}