#HeaderNav{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;

    width: 100%;
    height: 80px;
    left: 0;
    top: 0;

    overflow: hidden;
    background: black;
}

#HeaderNav *{
    z-index: 10;
}

.HeaderNavGroup{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    padding: 0;
    gap: 32px;

    height: 40px;

    flex: none;
    order: 0;
    flex-grow: 0;
}

.HeaderNavGroupLeft{
    width: max-content;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    transition: 0.5s;
}

.HeaderNavGroupRight{
    width: max-content;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    gap: 4px;
}

.HeaderNavLogo a{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    padding: 6px;
    gap: 8px;

    order: 0;
    flex-grow: 0;

    width: max-content;
}

.HeaderNavLogo img{
    width: 30px;
    height: 30px;

    flex: none;
    order: 0;
    flex-grow: 0;
}

.HeaderNavLogo h1 {
    height: 32px;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.02em;
    color: #FFFFFF;
    flex: none;
    order: 1;
    flex-grow: 0;
}

.HeaderNavSlice{
    background-color: white;
    width: 2px;
    height: 100%;
}


.HeaderNavNotLoggedIn{
    display: flex;
    gap: 5px;
}

#HeaderNav .nav-link {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0.2rem;
    padding: 0.4rem;
}

#HeaderNav .nav-link:hover {
    filter: drop-shadow(0 0 4px rgba(255, 255, 255, 0.1));
}

#HeaderNav .nav-item .active {
    font-size: 1rem;
    filter: brightness(1.2);
}

.miniLogo {
    width: 12px;
    height: 12px;
}

@media only screen and (max-width: 950px) {
    .HeaderNavGroupLeft{
        width: max-content;
        gap: 4px;
    }
    .HeaderNavLogo h1 {
        display: none;
    }
}

@media only screen and (max-width: 800px) {
    .HeaderNavGroupLeft{
        gap: 4px;
    }
    .HeaderNavSearchbar{
        display: none;
    }
    .HeaderNavLoggedIn{
        gap: 0;
    }
}