#Index {
    width: 100vw;
    overflow: hidden;
}

#Index .info-box .feature-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    height: 400px;
}

#Index .header-welcome {
    height: 250px;
    padding-bottom: 20px;
}

#Index .header-welcome img {
    width: 25px;
    height: 25px;
    filter: drop-shadow(0 0 20px white) drop-shadow(0 0 20px white) drop-shadow(0 0 20px white) drop-shadow(0 0 20px white);
    transition: 0.1s;
    cursor: pointer;
}

#Index .header-welcome img:hover {
    filter: drop-shadow(0 0 20px black) drop-shadow(0 0 20px black) drop-shadow(0 0 20px black) drop-shadow(0 0 20px black);
}

#Index i {
    font-size: 60px !important;
}

#Index .header-welcome .background-video {
    background: black;
    height: 250px;
}