@import url(https://fonts.googleapis.com/css?family=DM+Sans);
@import url(https://fonts.googleapis.com/css?family=Poppins);

@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.3/font/bootstrap-icons.css");

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: white;
  font-family: 'Poppins', serif;
}

#root {
  background-color: #070707;
}

#root h1, #root h2, #root h3, #root h4, #root h5, #root h6 {
  margin-bottom: 0;
}

#root h1{
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  color: #FFFFFF;
  flex: none;
  flex-grow: 0;
}

#root a{
  font-style: normal;
  font-weight: 600;
  font-size: 0.8rem;
  line-height: 16px;

  color: #b080ff;
  transition: 0.2s;
  text-decoration: transparent;
}

#root a:hover {
  color: #ffffff;
}

#root p{
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 16px;

  text-decoration: transparent;
}

#root input{
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 12px 10px 16px;
  gap: 18px;
  width: 256px;
  height: 40px;
  border: 2px solid #4000ff;
  border-radius: 15px;
  flex: none;
  order: 0;
  flex-grow: 0;
  background-color: rgba(0, 0, 0, 0.1);
  transition: 0.2s;
  z-index: 2;
}

#root input:focus{
  background-color: rgb(24, 0, 84);
  outline: none;
}

#root button{
  margin: 4px;
  padding: 4px 8px 4px 8px;
  background: transparent;
  border-radius: 15px;
  transition: 0.4s;
  color: #b080ff;
  border: transparent;
}

#root button:hover{
  cursor: pointer;
  color: #ffffff;
}

#root .alert{
  color: white;
  width: 100%;
  text-align: center;
  margin-top: 20px;
}

#root textarea{
  box-sizing: border-box;
  padding: 10px 12px 10px 16px;
  gap: 18px;
  width: 512px;
  height: 128px;
  overflow: visible;
  border: 2px solid #4000ff;
  border-radius: 15px;
  background-color: rgba(0, 0, 0, 0.1);
  transition: 0.2s;
  z-index: 2;
}

#root textarea:focus{
  background-color: rgb(24, 0, 84);
  outline: none;
}

/* SCROLLBAR */

/* width */
::-webkit-scrollbar {
  width: 1vh;
}

/* Track */
::-webkit-scrollbar-track {
  background: #3d3d3d;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(100, 100, 100);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #0e0e0e;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 64px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: rgb(255, 255, 255);
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -ms-transform: translateX(26px);
  transform: translateX(26px);
  background-color: rgb(0, 0, 0);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}