body{
  overflow-x: hidden;
}

#Content{
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  min-height: 80vh;
}

input::-ms-browse {
  background: black;
  color: red;
  padding: 1em;
}

.Page {
  width: 600px;
}