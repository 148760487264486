@import 'bootstrap/dist/css/bootstrap.min.css';
@import 'bootstrap-icons/font/bootstrap-icons.css';

body, html {
    background-color: #111;
    color: #fff;
    font-family: 'Inter', Arial, sans-serif;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}

#root {
    overflow-x: hidden;
}

#root * {
    z-index: 1;
    transition: 0.2s;
}

#root .container {
    display: flex;
    flex-direction: column;
    max-width: 100vw !important;
    padding: 0 !important;
}

#root .custom-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;
}

#root .custom-header h1{
    max-width: 80vw;
}

#root .custom-header-big {
    width: 80%;
    background: linear-gradient(90deg, rgba(176, 128, 255,0.5) 0%, rgb(122, 0, 251,0.5) 100%);
    padding: 40px 20px;
    margin-bottom: 2rem;
    z-index: 1;
}

#root .custom-header-small {
    width: 60%;
    background: linear-gradient(90deg, rgb(0, 33, 251,0.5) 0%, rgb(128, 179, 255,0.5) 100%);
    padding: 25px 100px;
    margin-bottom: 2rem;
    z-index: 1;
}

#root .custom-section {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    width: 100vw;
}

#root .background-video {
    position: absolute;
    z-index: -1;
    object-position: center;
    pointer-events: none;
    object-fit: contain;
    width: 100vw;
}

#root .info-box {
    display: flex;
    flex-direction: column;
    background-color: rgba(34, 34, 34, 0.8);
    padding: 20px;
    margin: 10px;
    border-radius: 8px;
    width: 400px;
    transition: all 0.3s;

    height: 400px;
}

#root .info-box:hover {
    scale: 1.05;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5);
    border: 4px solid #b080ff;
}

#root .info-box .icon {
    margin-left: auto;
    margin-right: auto;
    padding: 4px;
    font-size: 36px;
}

#root .info-box .feature-box button {
    color: white;
    font-size: 20px;
    background: #6100ff;

    padding: 20px;
}

#root .info-box .feature-box button:hover {
    filter: brightness(150%);
}

p {
    font-size: calc(0.6vw + 0.5rem) !important;
    font-weight: 200 !important;
    line-height: 1.15 !important;
    margin-bottom: 0 !important;
}

h1 {
    font-size: calc(0.6vw + 1rem) !important;
    font-weight: 800 !important;
}

h2 {
    font-size: calc(0.6vw + 0.9rem) !important;
    font-weight: 600 !important;
}

h3 {
    font-size: calc(0.6vw + 0.8rem) !important;
    font-weight: 600 !important;
}

h4 {
    font-size: calc(0.6vw + 0.7rem) !important;
    font-weight: 600 !important;
}

i {
    font-size: 1.5rem;
    color: #b080ff; /* Lila Farbakzent */
}

.smallText {
    font-size: 80%;
}

.custom-list {
    list-style: none;
    padding: 0;
}

.custom-list li {
    padding: 10px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.custom-list li:last-child {
    border-bottom: none;
}

a, a:hover {
    color: #b080ff; /* Lila Farbakzent */
    text-decoration: none;
}

p, li {
    line-height: 1.5;
}
